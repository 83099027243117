import React, {useState, useEffect} from 'react'
import './Modal.css'


const Modal = (props) => {

	return (
		props.modal && (
			<div className="modal-container">
				<div className="modal">
					<div className="modal-details">
						<h1 className="modal-title">Leave a Review, Save a Few</h1>
						<p className="modal-description">Drop a review on Google and recieve a month of free monitoring
						<br></br><br></br>
						<span style={{fontSize: '18px'}}>Please notify us after you've left a review so we can apply the free month to your billing cycle</span>
						</p>
						<small>*Valued at $20</small>
					</div>
					<a href='https://www.google.com/search?q=checkpoint+alarms&sxsrf=AOaemvJd9WaHWfo3Klfz3PtyFoF8GDzXdg%3A1630511543806&source=hp&ei=t6EvYcXyLZfj5NoPkZGkqAk&iflsig=ALs-wAMAAAAAYS-vx-49uANycbNeY-3Kjo1baDYHhYNp&oq=check&gs_lcp=Cgdnd3Mtd2l6EAMYADIECCMQJzIECCMQJzILCC4QxwEQrwEQkQIyBQgAEJECMggIABCABBDJAzIFCAAQkgMyBQgAEJIDMgsIABCABBCxAxCDATIOCC4QgAQQsQMQxwEQowIyBQgAEIAEOgcIIxDqAhAnOhEILhCABBCxAxCDARDHARDRAzoICAAQsQMQgwE6EQguEIAEELEDEIMBEMcBEKMCOg4ILhCABBCxAxDHARDRAzoLCC4QgAQQsQMQgwE6CAgAEIAEELEDULkGWOsJYNkQaAFwAHgAgAFiiAGfA5IBATWYAQCgAQGwAQo&sclient=gws-wiz#lrd=0x89c185a83e32d087:0x669a5d1288e56654,1,,,'
					className="modal-btn"
      		target="_blank">Leave Review &rarr;</a>	
					<button onClick={props.close} className="modal-close"></button>
				</div>
			</div>
		) 
	)
}

export default Modal
