export const accordianData = [
	{
		title: "How does monitoring work?", 
		content: `The alarm is connected to a "central station" who recieves all signals from properties that are secured with an alarm system. The central station monitors for alarms, faults, disturbances, phone and alarm troubles, and other critical information for your system. When any alarm is triggered, the central station is notified immediately who then relays the specific information to the appropriate emergency services.`
	}, 
	{
		title: "Does an alarm system require a landline?", 
		content: `No but a landline will reduce your costs and provide redundancy incase of a trouble to your backup radio communication channel. In the instance of no landline, then your primary communication channel would be a radio signal backup that communicates directly with the central monitoring station.`
	},
	{
		title: "How much does monitoring cost?", 
		content: `Monitoring begins at $23/month and varies depending on the amount of protection you have equipped on your property as well as the amount of properties and zones you have secured.`
	},
	{
		title: "Can I move about freely in my home if the alarm is on?", 
		content: `Yes! Our Honeywell GE systems use a state-of-the-art smart system that is designed to deactivate the motion sensors while keeping the outer perimeter equipment secure like windows and doors. This is a specific "mode" you can activate while your home. `
	},
	{
		title: "How does an alarm system work?", 
		content: `Essentially an alarm system is an interconnected closed circuit between piece of alarm device. When one device is activated it trips or opens at that point in the circuit sending a signal to the panel which then sends a signal to the central station and emergency services. This action is extremely quick and happens within milliseconds.`
	},
	{
		title: "Is there different types of alarms?", 
		content: `Yes there are wired or wireless, addressable, no addressable with each having different pros and cons. Call out office to learn more about each system and what would be most appropriate for you.`
	},
	{
		title: "Do I own my alarm equipment?", 
		content: `Yes, unlike other large companies who offer enticing and exceptionally cheap registration deals, the majority of contracts the fine print tells the customer that they essentially are leasing their equipment at that rate and is the reason why they can offer such economically-appearing offers. 
		
		With Checkpoint YOU OWN ALL EQUIPMENT.`
	}
];