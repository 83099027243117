export const accordianData = [
	{
		title: `Do the cameras record at night?`,
		content: `Yes, our cameras are capable of 24/7 light/dark video providing 1080p HD - 4k pixel quality.`
	},
	{
		title: `What is an IP camera?`,
		content: `An IP (Internet Protocol) camera is a digital video system that can send data over a internet network. These cameras have mobile and web applications where you can access your video feed and dashboadrd from anywhere on any device.`
	},
	{
		title: `Can the cameras zoom, rotate, or be remote controlled?`,
		content: `Yes, we have a selection of Pan, Tilt, Zoom cameras that are capable of varying degrees of functionality and can be controlled remotely as well as used traditionally.`
	},
	{
		title: `Can I view footage live or remotely?`,
		content: `Yes, with an IP camera this is the most attractive advantage and one of the main reasons why customers choose a IP camera over tradtional CCTV cameras.`
	},
	{
		title: `Can the cameras record audio?`,
		content: `Yes, most of our models can record audio at different distance ranges with exceptional clarity.`
	},
	{
		title: `What is a security DVR?`,
		content: `A DVR (Digital Video Recorder) is the device that holds the digital copy of a recording. These devices can go weeks to months with no maintanence and have a user friendly interface to change settings.`
	},
	{
		title: `How many hours can the DVR store?`,
		content: `This varies based a handful of different factors including the frame rate (FPS) a recording is taken in, the storage size of the DVR, the pixel density, and amount of cameras recording. We have cameras available with motion dection activation that only record when motion is detected. This can provide a valuable alternative saving you storage space and allowing you to archive a greater amount of footage.`
	}
]