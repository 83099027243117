import React, {useState, Fragment} from 'react'
import QuoteSubmission from '../../components/Quotes/QuoteSubmission'
import Accordian from '../../components/accordian/Accordian'
import '../../style.css'
import './Security.css'
import data from './securityData'
import {accordianData} from './accordianData'


const Security = () => {
	const [view, setView] = useState(data[0]);

	const handleClick = (item) => { 
		setView(item)
	};

	const renderSelection = (data) => {
		return data.map((item) => {
			return (
				<li className={view.id === item.id ? 'activeLi' : ''} 
						key={item.id}
						onClick={() => handleClick(item)}>
					<div
            className={view.id === item.id ? 'item' + ' ' + 'activeItem' : 'item'}>
            <p className={view.id === item.id ? 'itemHeader' + ' ' + 'active' : 'itemHeader'}>{item.title}</p>
						<img src={item.icon} className='listIcon' alt='icon'/>
          </div>
				</li>
			)
		})
	}


	return (
		<Fragment>
			<section className='top-section-security'>
					<h1>Residental And Commerical Security Systems</h1>
			</section>

			<div className='top-section-box'>
				<h4>With almost 50 years in security system design and installation, Checkpoint Alarms can help design a system that promises protection around any budget. All systems are fully customizable depending on your needs and the areas you would like to protect. Our products are easy to use and a technican is always a phone call away to give you support.
				</h4>
			</div>

			<main className='componentSection'>
				<h1 style={{fontFamily: 'Jura', fontSize: '32px', color: 'white'}}>Explore Security Options</h1>

				<div className='itemsList'>
					<ul>{renderSelection(data)}</ul>
				</div>
				
				<div className='itemSection'>
					<div className='itemContent'>
						<p style={{color: 'white', fontWeight: '300'}} className='activeItemDescription'>
									{view.description}
						</p>
						<ul className='product-list' style={{color: 'white'}}>
							{view.bullets.map((bullet, index) => (
								<li 
									key={index}
									className='bullet'
								>
									✓  {bullet}
								</li>
							))}
						</ul>
					</div>
					
					<div className='imageSection'>
						<img
							src={view.img}
							className='image'
							alt='logo'
						/>
					</div>
				</div>
				
				<h1 style={{marginTop: '4rem'}}>Commonly Asked Questions About Security Systems</h1>
				<Accordian data={accordianData}/>

				<QuoteSubmission />
									
			</main>
			
		</Fragment>
	)
}

export default Security