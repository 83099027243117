export const accordianData = [
	{
		title: `What is an access control system?`,
		content: `An access control system allows only authorzied personnel to enter a building through secured entrace points. These entrance points are equipped with either a key card fob or keypad. The doors remain locked until either a keyfob or correct PIN is recieved by the access control unit. `
	},
	{
		title: `Can an access card be individualized?`,
		content: `Yes, an access card can be issued to a specific individual or used as a guest access. This is helpful in circumstances of providing employees access to a system as well as one-time users.`
	},
	{
		title: `Should I have an access control system?`,
		content: `Access control provide a range of advantages including controlling who can gain access at different points in a building, added security from physical keys, customizable access settings like days and times a user can hold authorization. Being encrypted and encoded, the keys are practically impossible to replicate and easily able to deny access in the case of a lost or stolen card. All data is also recorded within a internal network which is useful in times of a security breach, theft, or other suspcious activity occurs.`
	}
]