import React, {Fragment} from 'react'
import { useForm, ValidationError } from '@formspree/react';
import Logo from '../../resources/checkpoint-name-icon.png'


export const QuoteSubmission = () => {
	const [state, handleSubmit] = useForm("mbjqzlwj");

	if (state.succeeded) {
		return (
			<div className='response'>
				<img src={Logo} />
				<p>Thanks for contacting us, a respresentative will get back to you with an answer as soon as possible</p>	
			</div>
		)
}

	return (
		<Fragment>
			<form id="fs-frm" onSubmit={handleSubmit}>
					<div className='form-head'>
						<h1>Get A Quote</h1>
						<h3>Fill out the form below or call <u><b>(732) - 899 - 7660</b></u> to talk to a specialist about how Checkpoint can secure your property</h3>
					</div>
					<fieldset id="fs-frm-inputs">
						<input type="text" name="name" id="full-name" placeholder="First and Last" required="" />
						<ValidationError 
							prefix="Full Name" 
							field="name"
							errors={state.errors}
						/>
						<input type="email" name="email" id="email-address" placeholder="Email" required="" />
						<ValidationError 
							prefix="Email" 
							field="emaiil"
							errors={state.errors}
						/>
						<fieldset className="location">
							<input type="text" name="city" placeholder="Town or City" required="" />
							<ValidationError 
							prefix="Location" 
							field="city"
							errors={state.errors}
						/>
							<input type="text" name="zip-code" placeholder="Zip Code" required="" />
							<ValidationError 
							prefix="Full Name" 
							field="zip-code"
							errors={state.errors}
						/>
						</fieldset>
						<textarea rows="2" name="note" id="note" placeholder="Include any relevant information about requested quote"/>
						<ValidationError 
							prefix="Additional Info" 
							field="note"
							errors={state.errors}
						/>
						<input type="hidden" name="_subject" id="email-subject" value="Quote" />
					</fieldset>
					<input type="submit" value='Get Free Quote' disabled={state.submitting}/>
				</form>
			
		</Fragment>
	)
}

export default QuoteSubmission
