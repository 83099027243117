import React, {Fragment} from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'
import { Icon } from '@iconify/react';
import facebookIcon from '@iconify-icons/logos/facebook';

 
const Footer = () => {
	return (
		<Fragment>
			<footer>
				<nav className='footer-nav'>
						<Link className="bottom-nav-link" to="/">Home</Link>
						
						<div className='column'>
							<Link className="bottom-nav-link" to="security-solutions">Security Solutions</Link>
							<ul className='column'>
								<li>Contact Sensors</li>
								<li>Motion Detectors</li>
								<li>Glass Break Detectors</li>
								<li>Panic Buttons</li>
								<li>Wireless Key Fobs</li>
								<li>Temperature & Water Level Sensors</li>
							</ul>
						</div>

						<div className='column'>
							<Link className="bottom-nav-link" to="/fire-protection">Fire Protection</Link>
							<ul className='column'>
								<li>Smoke Detection</li>
								<li>Carbon Monoxide Detection</li>
								<li>Heat Detection</li>
								<li>Horn Strobes</li>
								<li>Flow Valve Sensors</li>
								<li>Pull Stations</li>
								<li>Licensed Fire System Testing</li>
							</ul>
						</div>

						<Link className="bottom-nav-link" to="/about-us">About Us</Link>
						<Link className="bottom-nav-link" to="/contact">Contact</Link>

						<div className="column">
                <h3 style={{"padding": "1rem 0"}}>Follow Us</h3>
                  <a href="https://www.facebook.com/checkpointalarms/" target="blank">
										<Icon style={{'fontSize' : '2rem'}} icon={facebookIcon} />
									</a>
								<h3>732-899-7660</h3>
						</div>
				</nav>
					<p style={{marginTop: '1.5rem'}} className='footer-misc'>Checkpoint Alarm Systems &copy; 2021</p>
			</footer>
		</Fragment>
	)
}

export default Footer
