import React, {Fragment, useEffect} from "react";
import { useLocation } from "react-router";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './style.css'
import Navbar from "./components/navbar/Navbar";
import Home from "./pages/home/Home";
import Security from "./pages/security/Security";
import Fire from "./pages/fire/Fire";
import CCTV from "./pages/cctv/CCTV";
import CardAccess from "./pages/cardAccess/CardAccess";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Footer from "./components/footer/Footer";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Fragment>
        <Navbar />
        <section className='wrapper'>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/security-solutions' component={Security} />
            <Route exact path='/fire-protection' component={Fire} />
            <Route exact path='/cameras' component={CCTV} />
            <Route exact path='/card-access' component={CardAccess} />
            <Route exact path='/about-us' component={About} />
            <Route exact path='/contact' component={Contact} />
          </Switch>
        </section>
        <Footer />
      </Fragment>
    </Router>
  );
}

export default App;
