import React, {Fragment} from 'react'
import { ContactForm } from './contactForm/ContactForm'
import './Contact.css'
import right from '../../resources/right-arrow.png'
import contact from '../../resources/new-email.png'
import checklist from '../../resources/test.png'
import checkmark from '../../resources/checklist.png'

const Contact = () => {
	return (
		<Fragment>
			<section class="process">
				<h2 style={{color: "white"}}>Inquiry   <img src={right}/>   Installation</h2>
				<div class="process-box">
						<div class="bx">
								<img src={contact} />
								<p>Contact us and we will review your inquiry for the appropriate services</p>
						</div>
						<span class="iconify divider" data-icon="fluent:divider-short-24-regular" data-inline="false"></span>
						<div class="bx">
								<img src={checklist} />
								<p>We'll give you a call to discuss further details about your request</p>
						</div>
						<span class="iconify divider" data-icon="fluent:divider-short-24-regular" data-inline="false"></span>
						<div class="bx">
								<img src={checkmark} />
								<p>We'll set up an appointment at a time that works well for you and begin the process of securing your property</p>
						</div>
				</div>
			</section>	

			<ContactForm />
		</Fragment>
		
	)
}

export default Contact
