import React, {useState, Fragment} from 'react'
import QuoteSubmission from '../../components/Quotes/QuoteSubmission'
import Accordian from '../../components/accordian/Accordian'
import {accordianData} from './accordianData'
import data from './fireData'
import '../../style.css'
import './Fire.css'

const Fire = () => {
	const [view, setView] = useState(data[0]);

	const handleClick = (item) => {
		setView(item)
	};

	const renderSelection = (data) => {
		return data.map((item) => {
			return (
				<li className={view.id === item.id ? 'activeLi' : ''} 
						key={item.id}
						onClick={() => handleClick(item)}>
					<div
            className={view.id === item.id ? 'item' + ' ' + 'activeItem' : 'item'}>
            <p className={view.id === item.id ? 'itemHeader' + ' ' + 'active' : 'itemHeader'}>{item.title}</p>
						<img src={item.icon} className='listIcon' alt='icon'/>
          </div>
				</li>
			)
		})
	}


	return (
		<Fragment>
			<section className='top-section-fire'>
					<h1>Fire Protection</h1>		
			</section>

			<div className='top-section-box'>
				<h4>Fires and Carbon Monoxide leaks can happen at a moments notice and seconds can mean the difference. The advantage of a Fire Alarm System by Checkpoint is our systems run on a circuit and communicate with all the other detectors in your system. If one is triggered they all respond with an alarm, compared to local, battery operated equipment that will only alarm in the eqiupment that detects smoke or CO.
				<br/><br/>
				Put your trust in Checkpoint Alarms and our 50 years of experience in Fire Safety and Protection. Our systems can detect a fire before it starts or the invisible signs of a CO2 leak way before you're aware and alert First responders immediately. 
				</h4>
			</div>


			<main className='componentSection'>
				<h1 style={{fontFamily: 'Jura', fontSize: '32px', color: 'white'}}>Explore Fire Protection</h1>
				<div className='itemsList'>
					<ul>{renderSelection(data)}</ul>
				</div>

				<div className='itemSection'>
					<div className='itemContent'>
						<p style={{color: 'white', fontWeight: '300'}} className='activeItemDescription'>
									{view.description}
						</p>
						<ul style={{color: 'white'}}>
							{view.bullets.map((bullet, index) => (
								<li 
									key={index}
									className='bullet'
								>
									✓  {bullet}
								</li>
							))}
						</ul>
					</div>
					
					<div className='imageSection'>
						<img
							src={view.img}
							className='image'
							alt='logo'
						/>
					</div>
				</div>

				<h1 style={{marginTop: '4rem'}}>Frequently Asked Questions About Fire Alarm System </h1>
				<Accordian data={accordianData}/>
				<QuoteSubmission />		

			</main>
			
		</Fragment>
	)
}

export default Fire
