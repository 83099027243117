import React, {Fragment} from 'react'
import './About.css'
import team from '../../resources/team.JPG'

const About = props => {
	return (
		<Fragment>
			<section className='header'>
				<div className='header-txt'>
					<h1>Welcome to Checkpoint Alarms</h1>
					<h4>Established in 1972, 50 years experience in security and fire alarm installation, consultation, and service - <br></br> Checkpoint is <u>service with a smile</u></h4>
				</div>
				<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12218.113983684476!2d-74.1014451!3d40.0413054!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x669a5d1288e56654!2sCheckpoint%20Alarm%20Systems!5e0!3m2!1sen!2sus!4v1627254192050!5m2!1sen!2sus" width="50%" height="600" style={{border: 0}} loading="lazy"></iframe>
			</section>

			<main>
				<div className='team-section'>
					<img src={team}></img>
					<h3>Checkpoint Alarms</h3>
				</div>

				<div className='team-text'>
					<h2>About us</h2>
					<p>Checkpoint was aquired in 1972 by Richard Kisley in Brick, New Jersey. What began as a small garage operation making window tape sensors with his father for customers - a state of the art techonology at the time - has evolved into a full scale Security & Fire protection company with hundreds of clients throughout New Jersey.
					<br></br>					<br></br>

					For 50 years, Checkpoint has remained uncompromised in its values of providing exceptional "service with a smile" through our consistent customer satisfaction and continuation in using the highest quality alarm products.
					<br></br>					<br></br>

					Serving both commerical and residential properties, we are licensed and insured for fire installation/testing and security installation/testing alongside CCTV and Card Access installation/maintenance.
					<br></br>					<br></br>

					In addition, we have extensive experience with alarm system take over when you decide to change from your former alarm system provider.</p>
				</div> 
			</main>
			
		</Fragment>
	)
}


export default About
