// Image Imports
import smokeDetector from '../../resources/smoke-detector_product.png'
import heatDetector from '../../resources/heatdetector.png'
import co2Detector from '../../resources/co-alarm.png'
import pullStation from '../../resources/pullstation.png'
import strobes from '../../resources/horn-stobe.png'
import testing from '../../resources/smoke test.jpg'

// Icon Imports
import smokeIcon from '../../resources/smoke-detector.png'
import heatIcon from '../../resources/fire-sensor.png'
import coIcon from '../../resources/carbon-monoxide.png'
import pullIcon from '../../resources/fire-alarm.png'
import strobeIcon from '../../resources/emergency.png'
import testingIcon from '../../resources/survey.png'


const data = [{
	id:1,
	img: smokeDetector,
	title: 'Smoke Detectors',
	icon: smokeIcon,
	description: 'Smoke alarms save lives. Smoke alarms that are properly installed and maintained play a vital role in reducing fire deaths and injuries. If there is a fire in your home, smoke spreads fast and you need smoke alarms to give you time to get out. Our smoke and heat alarms, compared to most alarms in households which are independent battery operated, are interconnected meaning: when one sounds, they all sound!',
	bullets : [
		'It takes on average 7 minutes from the start of a fire for someone in the home to recognize there\'s a fire and to call the fire department and it only takes 3 minutes for a room to become fully engulfed in flames.',
		'A smoke alarm in contrast will detect smoke in most cases while a fire is still smoldering and sometimes before a flame is even established.',
		'A traditional battery operated smoke alarm will only alert locally within ear shot of the siren, whereas Checkpoints smoke detectors will make a siren along with alerting the fire department and medical services.'
	]
},
{
	id:2,
	img: heatDetector,
	title: 'Heat Detectors',
	icon: heatIcon,
	description: 'Sends an alarm when room temperatures reach a fixed point, or when there is increase in the ambient temperature.  Heat detectors communicate an alarm to the central control panel prior to reaching its fixed set point for high rates of rise, providing a timely response to both rapid and slow temperature increases allowing you to catch a fire before it happens.',
	bullets : [
		'The most common heat detector: "Rate of Rise" operates using two heat-sensitive thermocouples. One of the sensors monitors the heat transferred by convection or radiation while the other measures the background temperature of the room. An alarm sounds when there’s a disparity between the two, suggesting that a fire is about to break out.',
		'Location is critical when designing an alarm system. Checkpoint will work with you and suggest the best places to install these devices.',
		'Ideally, heat detectors are best installed on the ceiling and in places where a smoke detector would not be suitable because of high levels of smoke & fumes like garages, and in the kitchen near the oven or stove.',
		'The range of heat detection is 25 square feet so it\'s best to install these devices in the center of the room for maximum coverage.'
	]
},
{
	id:3,
	img: co2Detector,
	title: 'Carbon Monoxide Detectors',
	icon: coIcon,
	description: 'Often called the "invisible killer", carbon monoxide is an odorless, colorless gas created when fuels (such as gasoline, wood, coal, natural gas, propane, oil, and methane) burn incompletely. In the home, heating and cooking equipment that burn fuel are potential sources of carbon monoxide. Vehicles or generators running in an attached garage will also produce dangerous levels of carbon monoxide. ',
	bullets : [
		'These devices send an alarm signal when a rise in CO is detected before dangerous levels have been reached. CO can be harmful at smaller concentrations when exposed for a longer period of time, while increasing concentrations require diminishing exposure times to be harmful.',
		'The location of a CO detector is essential as CO has the same density of oxygen. It\'s recommended to install a CO detector on each floor of a home, outside sleeping areas, kitchens, and near or inside rooms where the furnace, and water heater is.'
	]
},
{
	id:4,
	img: pullStation,
	title: 'Pull Station',
	icon: pullIcon,
	description: 'Essentially a panic lever for fire emergencies. Required on most commercial properties. In its simplest form, the user activates the alarm by pulling the handle down, sending an alarm to the control panel and alerting the fire department. After operation, most fire alarm pull stations must be restored to the ready position using a special tool or key in order for the panel to be reset.',
	bullets : [
		
	]
},
{
	id:5,
	img: strobes,
	title: 'Horn Strobes & Sirens',
	icon: strobeIcon,
	description: 'Emits a flashing light and blaring siren when the fire alarm system is triggered. When these devices are deployed and wired to other fire protection devices, it will deploy when any other devices along the circuit are activated.',
	bullets : [
		'On commercial properties, typically located in hallways or near exit doors to guide occupants to safety.',
		'Customizable for multiple selectable tones with high and low volume settings',
		'On residential properties typically one mini-strobe is preferred for each floor'
	]
},
{
	id:6,
	img: testing,
	title: 'Fire System Testing',
	icon: testingIcon,
	description: 'Most importantly, after you have the prior devices installed on your property you need to ensure that all the components are working and communicating properly with each other within the fire circuit. Like all electronics, every device has an estimated shelf life and therefore needs to be tested for compliance and standards. By law, all smoke detectors are required to be inspected once per year commercially and is suggested the same in residential buildings. Checkpoint Alarms is licensed and insured to carry out these tests along with other device tests like Carbon Monoxide, Heat, Pull Station etc.',
	bullets : [
		
	]
}
]

export default data 