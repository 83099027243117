// Image Imports
import dome from '../../resources/dome_camera.jpg'
import bullet from '../../resources/bulletcamera.jpg'
import doorbell from '../../resources/doorbell_camera.png'
import view from '../../resources/mobile_view.png'

// Icon Imports
import domeIcon from '../../resources/dome.png'
import bulletIcon from '../../resources/bullet.png'
import doorbellIcon from '../../resources/video-doorbell.png'
import mobileViewIcon from '../../resources/responsive.png'


const data = [
	{
		id:1,
		img: dome,
		title: 'Dome Cameras',
		description: 'Dome cameras named for their shape, offer an extensible variety of features in a discreet package. Built to withstand all elements in indoor and outdoor settings, these cameras can be placed practically anywhere. Dome cameras equipped with LED will record quality video in low-light or no-light settings.',
		icon: domeIcon,
		bullets : [
			'Capable of Pan, Tilt, Zoom movement or as a panoramic camera depending on the products\' capabilities.',
			'Sleek design is beneficial in avoiding vandalism or obstruction and scaling coverage quickly and easily.',
			'Tinted lens encasing prevents onlookers from seeing where the camera is facing.',
			'Wide-angled lens provides maximum area coverage options.'
		]	 
	},
	{
		id:2,
		img: bullet,
		title: 'Bullet Cameras',
		description: 'Named for their cylindrical shape resembling a bullet, research has shown that the presence of bullet cameras makes a property less desirable to a criminal. These cameras are designed for both indoor and outdoor use, but are often found outdoors because of their long-range zoom capability and lipped cover above the lens to protect against sun glare.',
		icon: bulletIcon,
		bullets : [
		'Long distance viewing is the cameras main strength. The camera\'s narrow viewing angle allows the camera to see clearly at farther distances, capturing clear images of people and license plates at great distances.',
		'LED bullet cameras also allow long range night vision that other types of cameras would not be capable of, making these devices perfect for open areas outside.',
		'Because of their noticeable presence, these cameras are highly effective at deterring criminal behavior.',
		'Commonly used in: Parking lots, Hallways, Hospitals, Airports, Traffic Cameras, Gas stations, Hotels'
		]	 
	},
	{
		id:3,
		img: doorbell,
		title: 'Doorbell Cameras',
		icon: doorbellIcon,
		description: '24/7 eyes and ears on your front door. You’ll always know who and what is at your door – even when you’re away',
		bullets : [
			'High Quality video playback and real-time video access from virtually anywhere.',
			'Motion detection settings allow for customizable boundaries when you want to be alerted. It\'s like having a motion detector and camera outside your door, great for being alerted when packages arrive at your door or if a unexpected guest is walking towards the house.',
			'Two-way audio controls allow you to communicate with whose at your door while you\'re not in the house. Food or package deliverys you want to leave instructions for or don\'t want your kids to answer? These devices are the perfect options.',
			'Video storage options for cloud or SD card based available',
			'Infrared Night vision capability offering you vision outside your door, even when your eyes can\'t.'
		]	 
	},
	{
		id:4,
		img: view,
		title: 'Remote & Mobile Viewing',
		icon: mobileViewIcon,
		description: 'Have 24/7 access to your cameras from your smart phone, tablet, or PC. Upon installation, we will set you up with remote viewing ability through a secure server with login credentials or through your IP address that your system is set up on. In addition, many of these security camera apps allow you to control Pan/Tilt/Zoom settings, view multiple cameras simultaneously, playback footage, search through archived footage, download footage, and more.',
		bullets : [
			
		]	 
	},
]

export default data