import React from 'react'
import { useForm, ValidationError } from '@formspree/react';
import './ContactForm.css'
import Logo from '../../../resources/checkpoint-name-icon.png'

export const ContactForm = () => {
	const [sent, handleSent] = useForm("xwkaeeqa");
  if (sent.succeeded) {
      return (
				<div className='response'>
					<img src={Logo} />
					<p>Thanks for contacting us, a respresentative will get back to you with an answer as soon as possible</p>	
				</div>
			)
			
  }

	return (
		<div id='contact-form'>
			<form id='fs-frm' onSubmit={handleSent}>
				<fieldset id="fs-frm-inputs">
					<label htmlFor="full-name">Full Name</label>
						<input type="text" name="name" id="full-name" placeholder="First and Last" required="" />
						<ValidationError 
						prefix="Name" 
						field="name"
						errors={sent.errors}
					/>
					<label htmlFor="address">Address</label>
						<input type="text" name="address" id="address" placeholder="123 Sunrise Street" required="" />
						<ValidationError 
						prefix="Address" 
						field="address"
						errors={sent.errors}
					/>
											
					<label htmlFor="phone">Phone Number</label>
						<input type="text" name="phone" id="phone" placeholder="(732) - 012 - 3456" required="" />
						<ValidationError 
						prefix="Phone" 
						field="phone"
						errors={sent.errors}
					/>
											
					<label htmlFor="email-address">Email Address</label>
						<input type="email" name="_replyto" id="email-address" placeholder="email@domain.com" required="" />
						<ValidationError 
						prefix="Email" 
						field="email"
						errors={sent.errors}
					/>
				</fieldset>
				<fieldset id="fs-frm-selects">
					<label htmlFor="property_type">Select Property</label>
					<select name="property_type" id="property_type" required="">
					<option value="Choose" selected="" disabled="">Select</option>
					<option value="Residential">Residential</option>
					<option value="Commercial">Commercial</option>
					</select>
				</fieldset>
				<fieldset id="fs-frm-selects">
					<label htmlFor="service_type">Estimate Type</label>
					<select name="service_type" id="service_type" required="">
					<option value="Choose" selected="" disabled="">Select</option>
					<option value="burglar_alarm_system">Security Alarm System</option>
					<option value="fire_alarm_system">Fire Alarm System</option>
					<option value="camera_install">Cameras</option>
					<option value="card_access_install">Card Access</option>
					<option value="multiple_security_systems_install">Multiple Alarm Systems</option>
					</select>
				</fieldset>
				<label htmlFor="message">Additional Comments</label>
					<textarea rows="3" name="message" id="message" placeholder='' required=""></textarea>
					<input type="hidden" name="_subject" id="email-subject" value="Survey Responses" />
					<input type="submit" value="Send" />
    	</form>
		</div>
		
	)
}
