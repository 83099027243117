import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'
import Logo from '../../resources/checkpoint-name-icon.png' 

const Navbar = () => {
	const [click, setClick] = useState(false);
	const handleClick = () => setClick(!click);
  	const closeMenu = () => setClick(false);


	return (
		<div className="nav-wrapper">
			<div className="grad-bar"></div>
			<nav className="navbar">
				<Link to='/' onClick={closeMenu}>
					<img alt='logo' src={Logo} />
				</Link>
				<div className={click ? 'menu-toggle is-active' : 'menu-toggle'} id="mobile-menu" onClick={handleClick}>
					<span className="bar"></span>
					<span className="bar"></span>
					<span className="bar"></span>
				</div>
				<ul className={click ? 'mobile-nav' : 'nav'}>
					<li className="nav-item">
						<Link to="/security-solutions" onClick={closeMenu}>Security Solutions</Link>
					</li>
					<li className="nav-item">
						<Link to="/fire-protection" onClick={closeMenu}>Fire Protection</Link>
					</li>
					<li className="nav-item">
						<Link to="/cameras" onClick={closeMenu}>Cameras</Link>
					</li>
					<li className="nav-item">
						<Link to="/card-access" onClick={closeMenu}>Card Access</Link>
					</li>
					<li className="nav-item">
						<Link to="/about-us" onClick={closeMenu}>About</Link>
					</li>
					<li className="nav-item">
						<Link to="/contact" onClick={closeMenu}>Contact Us</Link>
					</li>
				</ul>
			</nav>
		</div>


	)
}

export default Navbar
