import React, {useState, useEffect} from 'react'
import './Accordian.css'
import { Icon } from '@iconify/react';
import previousIcon from '@iconify/icons-flat-color-icons/previous';

const Accordian = (props) => {
	const [items, setItems] = useState([]);

	useEffect(() => {
		setItems(props.data)
	}, [])

  
  const click = (i) => {
    const newAccordion = items.slice();
    const index = newAccordion.indexOf(i)
    
    newAccordion[index].open = !newAccordion[index].open;
    setItems(newAccordion);
		console.log(items)
  }

	 return (
		<div className="accordion">
			{items.map((i) => (
      <div className='title-wrapper' key={items.indexOf(i)}>
        <div className="title" onClick={click.bind(null, i)}>
         <div className="arrow-wrapper">
           <Icon icon={previousIcon} rotate={i.open ? 1 : 3 }></Icon>
         </div>
         <div className="title-text">
          	{i.title}
         </div>
       </div>
       <div className={i.open ? "content content-open" : "content"}>
					<div className={i.open ? "content-text content-text-open" : "content-text"}> 
						{i.content}
					</div>
      	</div>
      </div>
			))}
		</div>
	);
}

export default Accordian
