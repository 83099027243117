import React, {Fragment, useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import './Home.css'
import '../../style.css'
import { Icon } from '@iconify/react'
import checkmarkIcon from '@iconify-icons/flat-color-icons/checkmark'
import nextIcon from '@iconify-icons/flat-color-icons/next'
import checkMark from '@iconify-icons/noto-v1/check-mark'
import letterX from '@iconify-icons/noto-v1/letter-x'
import fireIcon from '../../resources/conflagration.png'
import cameraIcon from '../../resources/cctv-camera.png'
import homeIcon from '../../resources/home-security.png'
import cardAccessIcon from '../../resources/key-card.png'
import familyIcon from '../../resources/home.png'
import customerServiceIcon from '../../resources/customer-service.png'
import trust from '../../resources/trust.png'
import homeOutline from '../../resources/home-outline.png'
import dataSecurity from '../../resources/data-security.png'
import homeCheck from '../../resources/home-check.png'
import Logo from '../../resources/checkpoint-name-icon.png'
import Modal from '../../components/modal/Modal'
import spirits from '../../resources/spirits-removebg-preview.png'
import mcdonalds from '../../resources/mcdonalds_-removebg-preview.png'
import ford from '../../resources/ford-removebg-preview.png'




const Home = () => {
	const [modal, setModal] = useState(false)

	useEffect(() => {
		setTimeout(()=> {
			setModal(true)
		}, 5000)
	}, [])



	return (
		<Fragment>
			<section className="top-container">
				<div className="top-container-box">
						<h1 style={{fontSize: '30px'}}>Daily Alarm Monitoring For Less Than A Coffee</h1>
						<h3>Residential & Commercial</h3>
						<h3>Licensed, Insured & Trusted for 50 years</h3>
						<h3>Comprehensive protection packages for all scenarios</h3>
						<Link style={{width: 'fit-content'}} to='/contact'>
							<button className='md-btn arr-btn'>
								<span style={{color: '#FFFFFF'}}>
									Get Quote
								</span>
							</button>
						</Link>
				</div>
			</section>

			<section className='services'>
				<h1>Services</h1>
				<div className='services-row'>

					<div className='card'>
						<div className='card-header'>
							<img alt='icon' className='icon' src={homeIcon}/>
							<Link to='/security-solutions'>
								<button className='md-btn card-btn'>
									Security Services
									<Icon style={{verticalAlign: 'text-top'}} icon={nextIcon}/>
								</button>
							</Link>
						</div>
						<h3 style={{margin: '0'}}>Wired or Wireless</h3>
						<ul>
							<li>
							<Icon icon={checkmarkIcon}/> Window & Door Sensors
							</li>
							<li>
							<Icon icon={checkmarkIcon}/> Motion Dectectors
							</li>
							<li>
							<Icon icon={checkmarkIcon}/> Glass Break Detectors
							</li>
							<li>
							<Icon icon={checkmarkIcon}/> Panic Buttons
							</li>
							<li>
							<Icon icon={checkmarkIcon}/> Wireless Key Fob
							</li>
							<li>
							<Icon icon={checkmarkIcon}/> Temperature Sensors
							</li>
							<li>
							<Icon icon={checkmarkIcon}/> Water Level Sensors
							</li>
						</ul>
					</div>

					<div className='card'>
						<div className='card-header'>
							<img alt='icon' className='icon' src={fireIcon} />
							<Link to='/fire-protection'>
								<button className='md-btn card-btn'>
									Fire Protection
									<Icon style={{verticalAlign: 'text-top'}} icon={nextIcon}/>
									</button>
							</Link>
						</div>
						<ul>
							<li>
							<Icon icon={checkmarkIcon}/> Smoke Detection
							</li>
							<li>
							<Icon icon={checkmarkIcon}/> Carbon Monoxide Detection
							</li>
							<li>
							<Icon icon={checkmarkIcon}/> Heat Detection
							</li>
							<li>
							<Icon icon={checkmarkIcon}/> Horn Strobes & Sirens
							</li>
							<li>
							<Icon icon={checkmarkIcon}/> Flow Valve Sensors
							</li>
							<li>
							<Icon icon={checkmarkIcon}/> Pull Stations
							</li>
							<li>
							<Icon icon={checkmarkIcon}/> Licensed Fire System Testing
							</li>
						</ul>
					</div>

					<div className='card'>
						<div className='card-header'>
							<img alt='icon' className='icon' src={cameraIcon}/>
							<Link to='/cameras'>
								<button className='md-btn card-btn'>
									CCTV
									<Icon style={{verticalAlign: 'text-top'}} icon={nextIcon}/>
								</button>
							</Link>
						</div>
						<ul>
							<li>
							<Icon icon={checkmarkIcon}/> Ring / Nest Doorbell Cameras
							</li>
							<li>
							<Icon icon={checkmarkIcon}/> Mobile / Remote Viewing
							</li>
							<li>
							<Icon icon={checkmarkIcon}/> Pan / Tilt / Zoom Cameras
							</li>
							<li>
							<Icon icon={checkmarkIcon}/> Analog Systems
							</li>
							<li>
							<Icon icon={checkmarkIcon}/> IP Systems
							</li>
						</ul>
					</div>

					<div className='card'>
						<div className='card-header'>
							<img alt='icon' className='icon' src={cardAccessIcon} />
							<Link to='/card-access'>
								<button className='md-btn card-btn'>
									Card Access
									<Icon style={{verticalAlign: 'text-top'}} icon={nextIcon}/>
								</button>
							</Link>
						</div>
						<ul>
							<li>
							<Icon icon={checkmarkIcon}/> Conventional Systems
							</li>
							<li>
							<Icon icon={checkmarkIcon}/> Intergrated Systems
							</li>
							<li>
							<Icon icon={checkmarkIcon}/> Mag Locks "Fail Safe"
							</li>
							<li>
							<Icon icon={checkmarkIcon}/> Door Strikes "Fail Secure"
							</li>
						</ul>
					</div>

				</div>
			</section>

			<section className="banner">
							<h1>24/7 - 365 Days a Year<br></br> Alarm System Monitoring<br></br>
							<span>(Full Protection Monitoring Packages Starting @ <b style={{color: 'black'}}><u>$23/month</u></b>)</span><br></br>
							732-899-7660</h1>
			</section>

			<section className='standout'>
				<h1>Why Checkpoint Alarms Has Been A Leading Residential and Commerical Alarm Provider Throughout New Jersey Since 1972</h1>

				<div style={{justifyContent: 'center', padding: '2.5rem'}} className='row'>
					<div className="box">
						<img alt='icon' className='icon' src={customerServiceIcon} />
						<h4>24-7 Support From Alarm System Experts and Emergency Service</h4>
						<p>Unlike other alarm system providers where you are greeted by an automated message, transferred multiple times and stuck on hold, when you call <u>Checkpoint Alarms</u> you speak to a live representative everytime.</p>
					</div>

					<div className="box">
						<img alt='icon' className='icon' src={trust} />
						<h4>No Hidden Costs & Fine Print</h4>
						<p>No fine print and no hidden costs. We will explain the entire process and all details about installing your system and the services you are recieving. 
						</p>
					</div>

					<div className="box">
						<img alt='icon' className='icon' src={familyIcon} />
						<h4>Family Owned </h4>
						<p>We understand as a homeowner it can be weary to have strangers in the home. With Checkpoint Alarms, you can put your trust in us to keep you safe. Because we live alongside our customers in New Jersey, we put our entire company value in ensuring the safety and reliabilty for our neighbors. 
						</p>
					</div>
				</div>
			</section>

			{/* CLIENTS SECTION */}
			<section className='client-section'>
				<h1 style={{fontSize: '3rem', fontFamily: 'Lato', fontWeight: 'lighter', textAlign: 'center'}}>Some of our commercial customers</h1>
				<div className='client-container'>
					<img src={spirits} className='client-box'/>
					<img src={mcdonalds} className='client-box'/>
					<img src={ford} className='client-box'/>
				</div>
			</section>

			<section className='comparison'>
				<h1 style={{textAlign: 'center', fontSize: '28px', color: 'black'}}>See how Checkpoint Alarms stands out</h1>
				<h3 style={{textAlign: 'center', fontSize: '16px', color: '#6b6b6b', fontWeight: '100', paddingBottom: '20px'}}>Compare plans, pricing, and details between Checkpoint and its competitors</h3>
				<table>
					<tbody>
						<tr className='table-header'>
							<th style={{width:"50%", border: 'none', backgroundColor: 'white'}}></th>
							<th><img alt='logo' src={Logo} /></th>
							<th style={{color: '#6b6b6b', fontWeight: '400'}}>Competitors</th>
						</tr>
						<tr>
							<td>Complete ownership of equipment <span>1</span></td>
							<td><Icon icon={checkMark} /></td>
							<td><Icon icon={letterX}/></td>
						</tr>
						<tr>
							<td>24/7 Emergency Support</td>
							<td><Icon icon={checkMark} /></td>
							<td><Icon icon={letterX}/></td>
						</tr>
						<tr>
							<td>Family Owned & Operated</td>
							<td><Icon icon={checkMark} /></td>
							<td><Icon icon={letterX}/></td>
						</tr>
						<tr>
							<td>Contract Length</td>
							<td>2 years</td>
							<td>3-5 years</td>
						</tr>
						<tr>
							<td>Monthly Monitoring <span>2</span></td>
							<td>$23</td>
							<td>$36-$100+</td>
						</tr>
					</tbody>
				</table>
				<p><span className='table-sm-txt'>1. Compared to other alarm installers who lease their equipment for the duration of the contract. <br></br>
				2. Compared the lowest montly monitoring packages between Checkpoint and its competitors.</span></p>
			</section>

			<section className='cta'>
				<h1>Take the first step in protecting the people and things you love</h1>
				<h3>How it works</h3>

				<div style={{justifyContent: 'space-around'}} className='row'>
					<div className='cta-box'>
						<img alt='icon' className='icon' src={homeOutline} />
						<h4>Choose a package</h4>
						<p>When you contact Checkpoint Alarms, we will walk you through the various solutions available to suit your needs</p>
					</div>

					<div className='cta-box'>
						<img alt='icon' className='icon' src={homeCheck}/>
						<h4>Consultation & Estimate</h4>
						<p>An expert technician will design an economical protection plan maximizing safety and security</p>
					</div>

					<div className='cta-box'>
						<img alt='icon' className='icon' src={dataSecurity} />
						<h4>Installation</h4>
						<p>We'll discuss and explain all details in your plan, then set you up with state-of-the-art equipment when you're ready</p>
					</div>
				</div>

				<h1>Call today to start protecting your home <u><b>732-899-7660</b></u></h1>
			</section>
			<Modal 
				modal={modal}
				close={() => setModal(false)}
			/>
		</Fragment>
	)
}

export default Home
