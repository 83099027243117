import React, {Fragment, useState} from 'react'
import QuoteSubmission from '../../components/Quotes/QuoteSubmission'
import Accordian from '../../components/accordian/Accordian'
import '../../style.css'
import './CardAccess.css'
import data from './cardAccessData'
import {accordianData} from './accordianData'


const CardAccess = () => {
	const [view, setView] = useState(data[0]);

	const handleClick = (item) => { 
		setView(item)
	};

	const renderSelection = (data) => {
		return data.map((item) => {
			return (
				<li className={view.id === item.id ? 'activeLi' : ''} 
						key={item.id}
						onClick={() => handleClick(item)}>
					<div
            className={view.id === item.id ? 'item' + ' ' + 'activeItem' : 'item'}>
            <p className={view.id === item.id ? 'itemHeader' + ' ' + 'active' : 'itemHeader'}>{item.title}</p>
						<img src={item.icon} className='listIcon' alt='icon'/>
          </div>
				</li>
			)
		})
	}


	return (
		<Fragment>
			<section className='top-section-card'>
				<h1>Card Access</h1>
			</section>

			<div className='top-section-box'>
				<h4>Secure different points of access through a building with a variety of different access packages and products</h4>
			</div>

			<main className='componentSection'>
				<h1 style={{fontFamily: 'Jura', fontSize: '32px', color: 'white'}}>Explore Card Access Options</h1>
				<div className='itemsList'>
					<ul>{renderSelection(data)}</ul>
				</div>

				<div className='itemSection'>
					<div className='itemContent'>
						<p style={{color: 'white', fontWeight: '300'}} className='activeItemDescription'>
									{view.description}
						</p>
						<ul style={{color: 'white'}}>
							{view.bullets.map((bullet, index) => (
								<li 
									key={index}
									className='bullet'
								>
									✓  {bullet}
								</li>
							))}
						</ul>
					</div>
					
					<div className='imageSection'>
						<img
							src={view.img}
							className='image'
							alt='logo'
						/>
					</div>
				</div>
				
				<h1 style={{marginTop: '4rem'}}>Commonly Asked Questions About Card Access Systems</h1>	
				<Accordian data={accordianData}/>
				<QuoteSubmission />
									
			</main>
			
		</Fragment>
	)
}

export default CardAccess
