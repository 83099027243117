// Image Imports
import doorSensor from '../../resources/door_contact.png'
import windowSensor from '../../resources/window-sensor-visible.jpeg'
import glassBreak from '../../resources/glassbreak.png'
import motionSensor from '../../resources/motion.png'
import panicButton from '../../resources/panic_button.png'
import keyPad from '../../resources/keypad.png'

// Icon Imports
import doorIcon from '../../resources/smart-door.png'
import windowIcon from '../../resources/windows.png'
import glassIcon from '../../resources/glassbreak_icon.png'
import motionIcon from '../../resources/motion-sensor.png'
import panicIcon from '../../resources/panic-button.png'
import keypadIcon from '../../resources/keypad_icon.png'



const data = [{
	id:1,
	img: doorSensor,
	title: 'Door Sensors',
	icon: doorIcon,
	description: 'Doors are the weakest and most targeted points in a home for an intruder. These sensors also known as “contact sensors", "contact magnets", or "entry sensors” - are the most popular options in a security systems package. Door sensors come in a variety of different sizes and specifications but at their core they produce the same outcome:',
	bullets : [
		'One magnet attached to the frame and the other sensor attached to the door',
		'While the door is closed, the magnets create a closed circuit that renders the connection secure (as illustrated in the photo)',
		'When the entry point is opened, the two magnets will separate causing an open circuit and triggering an alarm',
		'(Available to be Hidden within the frame Or Visible)'
	]
	 
},
{
	id:2,
	img: windowSensor,
	title: 'Window Sensors',
	icon: windowIcon,
	description: 'Similar to door sensors, when the alarm is active and a window is opened it will trigger the high-decibel siren alerting you, the police, and the central monitoring station.' ,
	bullets : [
		'Available to be Hidden within the frame Or visible'
	]
	 
},
{
	id:3,
	img: glassBreak,
	title: 'Glass Break Detectors',
	icon: glassIcon,
	description: [
		'Glass break detectors are similar to a motion sensor, but instead are designed to detect the decibal frequency of the monitored area. This ranges between 15-30 feet depending on the device. When the minimum capcity is reached, an alarm is trigged.'
	],
	bullets : [
		'These devices work by detecting the frequency or shockwaves associated with glass breaking, a door being forced open, or either entry point being smashed into.', 
		
		'Typically mounted on a wall or ceiling near a group of windows or sliding doors. Reccomended to have one '
	]
	 
},
{
	id:4,
	img: motionSensor,
	title: 'Motion Sensors',
	icon: motionIcon,
	description: 'Motion detection has become the standard for residential and commercial security systems. When your home is armed these devices send out invisible radar, infrared, or photo sensing frequency waves. All Checkpoint Systems allow for the capability to arm a system while bypassing the motion dectectors, essentially securing the outer premises while you move freely about inside. ',
	bullets : [
		'Radar: Deploys ultrasonic sounds waves that emit then deflect off stationary surfaces and repeat until a disturbance is detected in the process.',

		'Infrared: Detect emitted infrared energy in the form of heat given off by any human or animal. When there is an increase in heat energy detected, the alarm will sense a disturbance and sound an alarm.',

		'Photo Sensing: Deploys ultra-focused laser or light beams at a stationary object and sounds an alarm when the beams are crossed.'
	] 
	 
},
{
	id:5,
	img: panicButton,
	title: 'Panic Buttons',
	icon: panicIcon,
	description: 'A device that when manually triggered, deploys an immediate response from the Police. These buttons can emit a siren or be silent depending on the customer\'s preference. In many cases fire and burglar panic buttons will sound an alarm while a medical button will not. Installation locations of panic buttons are best advised in areas that are easy to access or are around entry points like a front door.',
	bullets : [
		'Location is important because if you have an emergency the closer you are to the button, the faster the response chain can occur and better chances of frightening an intruder or alerting a medical response.',

		'We advise to have a minimum of one per floor in residential and commercial structures or more depending on the layout of the premise.',
		
		'**All keypads installed by Checkpoint Alarms have a Police, Fire, EMS panic button.'
	] 
	 
},
{
	id:6,
	img: keyPad,
	title: 'Keypads',
	icon: keypadIcon,
	description: 'The root of a security system, the keypad functions as the controls to your alarm system. This is not to be confused with the alarm control panel. The control panel is the “brains” of the security system like the hardware on your phone or computer, where as the keyboard and screen of your phone would be the keypad on your alarm. The keypad serves a variety of functions:',
	bullets : [
		'Arming / Disarming the system',
		'Programming new zones',
		'Panic Buttons',
		'Alerting the user which zones are currently triggered (open bedroom window/motion detected in family room/open garage door)',
		'Stay Mode: Enables all exterior devices like window/door sensors while disabling indoor devices like motion detectors. Excellent for when you want to be protected while you are in your home.',
		'Chime Mode: Enables a chime feature that will ring from the keypad when a zone is triggered like a door or window without sounding an alarm.',
		'Quick Exit: Arm the system with a single push exit button'
	] 
	 
}]

export default data