// Image Imports
import card from '../../resources/access_card.png'
import keypad from '../../resources/access_keypad.png'
import strike from '../../resources/strike.png'

// Icon Imports
import cardIcon from '../../resources/smart-key.png'
import keypadIcon from '../../resources/access-keypad.png'
import lockIcon from '../../resources/door-lock.png'


const data = [
	{
		id:1,
		img: card,
		title: 'Access Cards',
		icon: cardIcon,
		description: 'Access cards can be thought of as an electronic "key". The access card is used to gain access through the doors secured by the access control system. Each access card is uniquely encoded to each user. Most access cards are approximately the same size as a standard credit card and can easily be carried in a wallet or purse.',
		bullets : [
			'Card readers are the devices used to electronically "read" the access card',
			 'Card readers may be of the "insertion" type (which require insertion of the card into the reader), or may be of the "proximity" type (which only require that the card be held in a 3" to 6" proximity of the reader', 
			 'Card readers are usually mounted on the exterior (non-secured) side of the door that they control'
		]	 
	},
	{
		id:2,
		img: keypad,
		title: 'Access Keypads',
		icon: keypadIcon,
		description: 'Access control keypads are devices which may be used in addition to or in place of card readers. The access control keypad has numeric keys which look like the keys on a touch-tone telephone. The access control keypad requires that a person desiring to gain access enter a correct numeric code or can accept a card scan depending on the model.',
		bullets : [

		]	 
	},
	{
		id:3,
		img: strike,
		title: 'Lock Hardware',
		icon: lockIcon,
		description: 'Electric lock hardware is the equipment that is used to electrically lock and unlock each door that is controlled by the access control system. In almost all cases, the electric lock hardware is designed to control entrance into a building or secured space. To comply with building and fire codes, the electric lock hardware never restricts the ability to freely exit the building at any time. There are a wide variety of different types of electric lock hardware. These types include:',
		bullets : [
			'Electric locks', 'Electric strikes', 'Electromagnetic locks', 'Electric exit devices',
		]	 
	},
]

export default data