export const accordianData = [
	{
		title: `What types of fire alarms are there?`,
		content: `There are two major types of fire systems, addressable and no-addressable (conventional). Addressable systems are ideal for large warehouses, office buildings, and building complexes. These systems have a special panel that notifies the fire department about the specific devices that triggered the alarm. Of course, with the added functionality these systems cost more. Convential systems are more suited for smaller properties where the location of the fire is apparent.`
	},
	{
		title: `How is the fire department notified when there's a fire?`,
		content: `When a device is triggered through it's operating mechanism, it will trip the looped circuit connecting all devices to the panel and relay an emergency to the alarm panel. Like a security system, the alarm will send that signal to the central station who then immedietaly notifies the fire department. This process happens within milliseconds.`
	},
	{
		title: 'Why is one fire alarm test required once a year?',
		content: `This is required by law and is also essential in ensuring all equipment is functioning properly. Checkpoint Alarm Systems is certified and extensively trained in testing, diagnosis, and repair/replacement for all fire alarm system equipment.`
	},
	{
		title: `What happens during a fire alarm system test?`,
		content: `During a fire alarm system test, a technician will test all smoke detectors, check the battery health, inspect the alarm panel and connection throughout the circuit, and ensure all CO monitors are working properly. This is the standard, but properties with additonal equipment not mentioned will also through be inspected for proper functionality.`
	},
	{
		title: `Why is my fire alarm occasionally beeping?`,
		content: `More often than not, a beeping or chirping fire alarm is not broken and likely needs the battery replaced. Depending if the system is wired or wireless, this could mean the individual device or the alarm panel. In addition, beeping can be attributed to a power surge, excessive dust and debris around the device, or an improper connection to the device. Call us at (732) 899-7660 if your system is beeping and we will take care of it for you.`
	},
	{
		title: `How do I stop my fire alarm from beeping?`,
		content: `On all newer keypads, the panel will log to the keypad the error message and at what location the error is occuaring. In most cases we can troubleshoot the beeping over the phone. Call our office at (732) 899 - 7660, 7 days a week at the first occurance of a trouble.`
	},
	{
		title: `Is fire alarm monitoring worth it?`,
		content: `Yes absoulutely. Besides providing a line of defense to unforseen accidents, you're providing constant protection for your family, yourself, and what you care about most. Additionally, most insurance companies will offer rebate or discount for having a fire alarm installed on your property.`
	},
	{
		title: `What happens if I lose power?`,
		content: `If you lose power, the battery connected to the control panel will supply power to your system for approximately 6 hours. After this, if you have a secondary power supply equipped with your system - the backup battery will provide enough capacity to power the entire system on standby for 24 hours.`
	},
]